<template>
	<div class="index-container">
		<el-row :gutter="20">
			<!-- 资源概况 -->
			<el-col :span="24">
				<el-card>
					<el-row :gutter="20" type="flex">
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #6777EF;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.product_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">个</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									项目数量
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #51E3C0;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.model_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">个</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									型号数量
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #FFA426;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.upgrade_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">个</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									更新数量
								</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="flex flex-column justify-center" style="height: 148px;background-color: #FC544B;">
								<div class="w-100 flex-all text-white font-weight-bold" style="font-size: 38px;">
									{{information.dcl_num || 0}}<span class="font-lg mt-1" style="margin-left: 5px;">个</span>
								</div>
								<div class="w flex-all font-lg text-white mt-1">
									待处理
								</div>
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>

		<el-row :gutter="20" class="mt-2">
			<!-- 维护记录 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '0px' }">
					<div class="flex align-center isCell fs_18">
						<span class="left-flag"></span>更新统计
					</div>
					<div class="tab_content" style="padding: 20px">
                       <div ref="chart1" style="width: 100%; height: 300px;"></div>
					</div>
				</el-card>
			</el-col>

			<!-- 系统日志 -->
			<el-col :span="12">
				<el-card :body-style="{ padding: '0px' }">
					<div class="flex align-center isCell fs_18">
						<span class="left-flag"></span>系统日志
					</div>
					<div class="tab_content" style="padding: 20px">
						<el-table :data="system_log" size="small" highlight-current-row height="300">
							<el-table-column prop="username" label="名称" align="center"></el-table-column>
							<el-table-column prop="method" label="操作方法" align="center"></el-table-column>
							<el-table-column prop="desc" label="描述" align="center"></el-table-column>
							<el-table-column prop="ctime" label="创建时间" min-width="100" align="center"></el-table-column>
						</el-table>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import * as echarts from "echarts"
	export default {
		name: 'Index',
		data () {
			return {
                information:{},
				system_log:[],
				chart1: {
					title: {},
					tooltip : {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							label: {
								backgroundColor: '#6a7985'
							}
						}
					},
					legend: {
						data:['新增更新']
					},
					grid: {
						left: '2%',
						right: '2%',
						bottom: '0%',
						containLabel: true
					},
					xAxis : [
						{
							type : 'category',
							boundaryGap : false,
							data : []
						}
					],
					yAxis : [
						{
							type : 'value'
						}
					],
					series : [
						{
							name:'新增更新',
							type:'line',
							stack: '总量',
							label: {
								normal: {
									show: true,
									position: 'top'
								}
							},
							areaStyle: {normal: {}},
							data:[120, 132, 101, 134, 90, 230, 210]
						},
					]
				},
			}
		},
		mounted(){
			this.getInformation()
            this.systemLog()
			this.drawLine1()
		},
		methods:{
            getInformation(){
			   this.axios.get('/manage/information').then(res=>{
				 this.information = res.data
			   })
            },
			systemLog(){
				this.axios.get('/manage/operation_log/list',{
					params:{
						page:1,
						limit:10
					}
				}).then(res=>{
					this.system_log = res.data
				})
			},
			drawLine1() {
				this.axios.get('/manage/upgrade_statistics').then(res=>{
					if(res.status){
						let result = res.data
						this.chart1.legend.data = result.legend.data
						this.chart1.xAxis[0].data = result.xAxis[0].data
						this.chart1.series[0].name = result.series[0].name
						this.chart1.series[0].data = result.series[0].data
					}
					this.$nextTick(()=>{
						let myChart = echarts.init(this.$refs.chart1)
						myChart.setOption(this.chart1)
					})
				})
			},
		},
}
</script>

<style>
.left-flag{
	width: 8px;
	height: 25px;
	background: #018EED;
	margin-right: 10px;
}
</style>
